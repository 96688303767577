import styled from "styled-components"
import { device } from "../styles/stylesConfig"
import { Rights } from "./Rights"
import { useSelector } from "react-redux"
import { getFullDate } from "../utils/Utilities"
import { ChangeEvent, CSSProperties, useState } from "react"
import { showMixinToast } from "../utils/Alerts"
import Loader06 from "./loaders/Loader06"

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1001;
    background-color: #111111;

    @keyframes hide {
        0% {
            opacity: 1;
        }
        25% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }

    &.active {
        animation: hide 1s ease;
    }
`

const ContentContainer = styled.div`
    width: 100%;
    height: 100%;
    max-height: 700px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
`

const TitleContainer = styled.div`
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    h1 {
        font-size: 32px;
        text-transform: uppercase;
        color: #FFF;
        letter-spacing: 10px;
        text-align: center;

        @media ${device.md} {
            font-size: 22px;
        }

        @media ${device.sm} {
            font-size: 18px;
        }

        @media ${device.xs} {
            font-size: 16px;
        }

    }

`

const InfoContainer = styled.div`
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const RightsDiv = styled.div`
    width: 100%;
    position: absolute;
    bottom: 0px;
`

const InputDiv = styled.div`
    width: 70%;
    max-width: 500px;
    padding: 0px 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const Input = styled.input`
    width: 100%;
    height: 45px;
    max-width: 500px;
    border-radius: 5px;
    border: 2px solid white;
    background-color: transparent;
    color: white;
    padding: 0px 15px;
    font-size: 14px;
    transition: all 0.5s ease;


    &:focus {
        outline: 0 none;
        border-color: #4baf51;
    }
`

const Button = styled.button`
    width: fit-content;
    max-width: 500px;
    margin-top: 15px;
    font-size: 14px;
    font-weight: 600;
    color: white;
    background-color: #4baf51;
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
    padding: 15px 30px;
    border-radius: 50px;
    overflow: hidden;
`

const Logo = styled.img`
    width: 100px;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 3px;
    right: 0px;
    margin: auto;
    z-index: 2;

    @media ${device.lg} {
        width: 100px;
    }

    @media ${device.md} {
    }

    @media ${device.sm} {
    }

    @media ${device.xs} {

    }

`



interface Props {
    showInProgress: boolean;
    passwordOk: boolean;
    setPasswordOk: React.Dispatch<React.SetStateAction<boolean>>
}

export const Building = ({ showInProgress, passwordOk, setPasswordOk }: Props) => {

    const { loading } = useSelector((state: any) => state.ui);

    const [form, setForm] = useState('');

    const handleKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            handleConfirm()
        }
    };

    const handleConfirm = () => {

        if (passwordOk === false && form.trim() !== '' && form.trim() === process.env.REACT_APP_IN_PROGRESS_PASSWORD) {

            showMixinToast('Acceso permitido', 'success', 'bottom');

            setPasswordOk(true);

            localStorage.setItem('passwordExpire', (parseInt(getFullDate()) + 10000000).toString().padEnd(17, '0'));

            setTimeout(() => {
                window.location.reload();
            }, 2500);

        } else {
            showMixinToast('contraseña incorrecta', 'error', 'bottom');
        }

    }

    const styleCompleted: CSSProperties = {
        outline: '0 none',
        borderColor: '#4baf51',
    }

    return (

        <Container className={loading === 0 ? 'active' : ''}>

            <ContentContainer style={{
                justifyContent: showInProgress ? 'space-around' : 'center'
            }}>

                {
                    showInProgress && (

                        <TitleContainer>

                            <h1>Pagina en proceso</h1>

                        </TitleContainer>
                    )
                }

                <InfoContainer>

                    {!showInProgress && <Loader06 />}
                    <Logo
                        loading="eager"
                        src={`${process.env.REACT_APP_CDN}/logo.webp`}
                        alt="Glow Ink"
                    />


                </InfoContainer>

                {
                    showInProgress && (

                        <InputDiv>

                            <Input
                                type="text"
                                placeholder="Ingrese la clave..."
                                value={form}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => setForm(e.target.value)}
                                onKeyDown={handleKeyPress}
                                style={(form.trim() !== '') ? { ...styleCompleted } : {}}
                            />
                            <Button onClick={() => handleConfirm()}>Ingresar</Button>

                        </InputDiv>

                    )
                }
            </ContentContainer>

            {
                showInProgress && (

                    <RightsDiv>
                        <Rights />
                    </RightsDiv>
                )
            }

        </Container >

    )
}

export default Building;