import { useEffect, useState, Suspense, lazy } from "react"
import { useLocation } from "react-router-dom"
import { scrollFadeInBottom, scrollFadeInCenter, scrollFadeInFastCenter, scrollFadeInFastLeft, scrollFadeInFastRight, scrollFadeInFastTop, scrollFadeInLeft, scrollFadeInRight, scrollFadeInTop } from "../effects/Observers";
import { useSelector } from "react-redux"
import Building from "../components/Building";
import styled from "styled-components";
import { getFullDate } from "../utils/Utilities";
import Footer from "../components/Footer";
import WhatIncludes from "../components/WhatIncludes";
import VideoSection from "../components/VideoSection";
import Fyi from "../components/Fyi";
import Colaboration from "../components/Colaboration";
import { Reviews } from "../components/Reviews";
import DoIt from "../components/DoIt";
import { ContactForm } from "../components/ContactForm";
import ServiceImagesModal from "../components/ServiceImagesModal";
import { LoadingScreen } from '../components/LoadingScreen';

const Header = lazy(() => import("../components/Header"));
const Introduction = lazy(() => import("../components/Introduction"));
const Services = lazy(() => import("../components/Services"));
const Rights = lazy(() => import("../components/Rights"));

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
`

export const HomeScreen = () => {

    const [showPreloader, setShowPreloader] = useState(true);

    const [imagesToShowModal, setImagesToShowModal] = useState<string[] | null>(null);

    const [passwordOk, setPasswordOk] = useState((localStorage.getItem('passwordExpire') !== undefined && localStorage.getItem('passwordExpire') !== null && parseInt(getFullDate()) < parseInt(localStorage.getItem('passwordExpire')!)) ?? false);

    const location = useLocation();

    const { loading, loadingAsync } = useSelector((state: any) => state.ui);

    const preloader = () => {

        if (loading === 0) {

            setTimeout(() => {
                setShowPreloader(false);
            }, 700);

        }

    };

    useEffect(() => {

        preloader()

        scrollFadeInTop();
        scrollFadeInFastTop();

        scrollFadeInBottom();

        scrollFadeInCenter();
        scrollFadeInFastCenter();

        scrollFadeInRight();
        scrollFadeInFastRight();

        scrollFadeInLeft();
        scrollFadeInFastLeft();

    }, [location.pathname, loading])


    if (process.env.REACT_APP_IN_PROGRESS === '1' && !passwordOk) return <Building showInProgress passwordOk={passwordOk} setPasswordOk={setPasswordOk} />

    return (

        <Container>

            {showPreloader && <Building showInProgress={false} passwordOk={passwordOk} setPasswordOk={setPasswordOk} />}

            {loadingAsync && <LoadingScreen />}

            <Suspense fallback={<Building showInProgress={false} passwordOk={passwordOk} setPasswordOk={setPasswordOk} />}>

                {(imagesToShowModal !== null) && <ServiceImagesModal showModal={imagesToShowModal} setShowModal={setImagesToShowModal} />}

                <Header />

                <Introduction />

                <Services setImagesToShowModal={setImagesToShowModal} />

                <WhatIncludes />

                <VideoSection />

                <Fyi />

                <Colaboration />

                <Reviews />

                <DoIt />

                <ContactForm />

                <Footer />

                <Rights />

            </Suspense>

        </Container>

    )
}
