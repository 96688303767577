import { types } from '../types/types';
import { getRandomColorState } from '../utils/Utilities';

const initialState = {
    primaryColor: getRandomColorState(localStorage.getItem('primaryColor') ?? '1'),
    loading: 1,
    loadingAsync: false,
    msgError: null
}

export const uiReducer = ( state = initialState, action ) => {

    switch ( action.type ) {

        case types.uiSetError:
            return {
                ...state,
                msgError: action.payload
            }   

        case types.uiRemoveError:
            return {
                ...state,
                msgError: null
            }

        case types.uiStartLoading:
            return {
                ...state,
                loading: state.loading + 1
            }

        case types.uiFinishLoading:
            return {
                ...state,
                loading: state.loading - 1
            }

        case types.uiStartLoadingAsync:
            return {
                ...state,
                loadingAsync: true
            }

        case types.uiFinishLoadingAsync:
            return {
                ...state,
                loadingAsync: false
            }

        case types.uiSetPrimaryColor:
            return {
                ...state,
                primaryColor: action.payload
            }

        default:
            return state;

    }

}