import styled from "styled-components"
import { useState } from "react"
import { finishLoading } from "../actions/ui"
import { useDispatch } from "react-redux"

const Container = styled.div`
    width: 100vw;
    height: 120vh;
    position: relative;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        margin: auto;
        width: 150%;
        height: 200px;
        left: -30%;
        top: -200px;
        z-index: 1;
        background-color: #111;
        transform: rotate(180deg);
        box-shadow: 50px -75px 50px 0px #111;
    }
    
    &::after {
        content: '';
        position: absolute;
        margin: auto;
        width: 150%;
        height: 200px;
        left: -50%;
        bottom: -200px;
        background-color: #111;
        box-shadow: 50px -75px 50px 0px #111;
    }
`

const VideoContainer = styled.video`
    width: 100vw;
    height: 120vh;
    object-fit: cover;
    
    // VIDEO FIXED //
    /* position: fixed;
    z-index: 0;
    top: 0px;
    left: 0px; */
`

export const VideoSection = () => {

    const [introVideo] = useState(`${process.env.REACT_APP_CDN}/video2.webm`);

    const dispatch = useDispatch()

    return (

        <Container>
            {
                introVideo ? (

                    <VideoContainer
                        autoPlay
                        muted
                        loop
                        playsInline
                        onLoadedData={() => { dispatch(finishLoading()) }}
                    >
                        <source src={introVideo} type="video/mp4" />
                    </VideoContainer>

                ) : <></>
            }
        </Container>
    )
}

export default VideoSection;